@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.wrapper {
  display: flex;
}

.radio input[type="radio"] {
  display: none;

  & + label {
    @include flexbox.flexbox;
    @include fontSize.spoqaR14;
    width: fit-content;
    height: 34px;
    padding: 8px 15px;
    margin-right: 8px;
    color: colors.$GRAY5;
    background-color: colors.$WHITE;
    border: 1px solid colors.$GRAY5;
    border-radius: 8px;
    cursor: pointer;
    width: 58px;
  }

  &:disabled + label {
    color: colors.$GRAY5;
    background: #f5f5f5;
  }

  &:checked + label {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background-color: colors.$BLUE1;
  }
}
