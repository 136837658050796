@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  position: relative;
  width: 100%;
  height: 8px;
  background-color: colors.$GRAY2;
  border-radius: 20px;
}

.label {
  position: absolute;
  bottom: calc(100% + 10px);
  @include fontSize.spoqaR12;
  color: colors.$GRAY7;
}

.progress {
  width: 25%;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 20px;
}

.tooltip {
  @include flexbox.flexbox(center, center);
  position: absolute;
  bottom: calc(100% + 10px);
  min-width: 51px;
  height: 38px;
  padding: 8px 12px;
  margin-left: -30px;
  @include fontSize.spoqaR14;
  color: colors.$WHITE;
  text-align: center;
  background-color: colors.$BLACK;
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 5%));
  border-radius: 12px;
}

.triangle {
  position: absolute;
  bottom: calc(100% + 8px);
  width: 10px;
  height: 10px;
  margin-left: -7px;
  background-color: colors.$BLACK;
  transform: rotate(45deg);
}
