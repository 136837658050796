/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 12px solid #eff6ff;
}

.bannerImage {
  width: 100%;
  height: 208px;
}

.emptyImg {
  width: 100%;
  height: 208px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
  text-align: center;
  @include fontSize.spoqaB18;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;

  .title {
    @include fontSize.spoqaB18;
    min-height: 64px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    border-bottom: 1px solid #dddddd;
  }

  .company {
    @include fontSize.spoqaR14;
    color: #7c7c7c;
    margin-bottom: 17px;
  }

  .mockButtons {
    display: flex;
    gap: 4px;
    padding-top: 16px;

    > div {
      padding: 7px 6px;
      font-size: 12px;
      color: #7c7c7c;
      background-color: #f4f4f4;
      border-radius: 8px;
    }

    .highlight {
      color: colors.$WHITE;
      background-color: #fc596c;
    }
  }
}
