/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.innerPannel {
  padding: 40px;
  margin-bottom: 24px;
  overflow: hidden;
  background-color: colors.$WHITE;
  border-radius: 8px;
}

.header {
  @include flexbox.flexbox(between);
  @include market.subTitle;

  .dateWrapper {
    @include flexbox.flexbox(start);
    @include fontSize.spoqaR16;

    > p {
      color: colors.$GRAY5;
    }
  }
}

.report {
  &Wrapper {
    @include fontSize.spoqaR14;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 180px 1fr;
    grid-row-gap: 16px;
    grid-column-gap: 40px;
    margin-bottom: 16px;
  }

  &Title {
    @include fontSize.spoqaR16;
    color: colors.$GRAY5;
    line-height: 34px;
  }

  &Content {
    @include fontSize.spoqaR16;
    line-height: 34px;
  }
}

.response {
  &Wrapper {
    @include flexbox.flexbox(start, start);
    @include fontSize.spoqaB18;
    flex-direction: column;
    margin-bottom: 40px;
    gap: 24px;
  }

  &Detail {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px 40px;
    @include fontSize.spoqaB16;
  }

  &Title {
    @include flexbox.flexbox(start);
    @include fontSize.spoqaR14;
    color: colors.$GRAY5;
  }
}

.dataDownload {
  background-color: colors.$WHITE;
  border-radius: 8px;
  height: 60px;
  padding: 0 40px;
  margin-bottom: 24px;
  @include flexbox.flexbox(between);
  @include fontSize.spoqaB20;

  button {
    width: 123px;
    height: 40px;
    margin-left: 8px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 1.6%);
  }
}
