.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  margin-bottom: 36px;
  font-size: 14px;

  .img {
    width: 100px;
    height: 100px;
  }

  .contents {
    display: flex;
    flex-direction: column;

    > h3 {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-bottom: 12px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;

      > svg {
        width: 20px;
        height: 20px;
      }
    }

    > ul {
      text-align: left;

      > li {
        margin-left: 24px;
        line-height: 24px;
        list-style: disc;
        color: #4d5256;

        > span {
          font-weight: 600;
          color: #4d5256;
        }
      }

      .firstLine {
        list-style: none;
        margin-left: 0px;
      }
    }
  }
}
