@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/position";

.container {
  @include flexbox.flexbox(_, center);
  @include fontSize.spoqaR16;
  height: 36px;

  label {
    width: 200px;
    color: colors.$GRAY5;
    margin-right: 40px;

    span {
      color: #fc596c;
    }
  }

  .radioBox {
    @include flexbox.flexbox(_, center);
    margin-right: 24px;
  }

  input[type="radio"] {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
    background-color: colors.$WHITE;
    border: 1px solid colors.$GRAY3;
    border-radius: 50%;

    &::after {
      @include position.middleBox;
      width: 10px;
      height: 10px;
      content: "";
      background-color: colors.$GRAY4;
      border-radius: 50%;
    }

    &:checked {
      background-color: colors.$WHITE;

      &::after {
        background-color: var(--primary-color);
      }
    }
  }

  .radioLabel {
    width: fit-content;
    color: #262626;
    margin: 0;
  }
}

.nullStyle {
  input {
    background-color: #ffefef;
    border: 1px solid colors.$RED1;

    &::placeholder {
      color: colors.$RED1;
    }

    &:focus {
      border: 1px solid colors.$RED1;
    }
  }
}
