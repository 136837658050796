@use "style/config/constants/colors";

.container {
  width: 100%;

  .panel {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .input_container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    label.radio {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      span {
        font-weight: 500;
        padding-top: 5px;
        color: colors.$GRAY6;
      }
    }
    .json_tree {
      * {
        color: colors.$GRAY6;
      }
    }
    .register {
      display: flex;
      align-items: center;
      button {
        margin-left: 32px;
      }
    }
  }

  .radio_container {
    display: flex;
    gap: 16px;
  }

  .error_input {
    border-color: colors.$RED1;
  }

  .did_filtering_container {
    display: flex;
    justify-content: flex-end;
  }
}
