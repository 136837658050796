@use "style/pages/market/marketMixins" as market;

.wrapper {
  .subTitle {
    @include market.subTitle;
    margin-bottom: 8px;
  }
  .filtering_wrapper {
    display: flex;
    gap: 16px;
  }
}
