@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.wrapper {
  border: 1px solid colors.$GRAY3;
  border-radius: 8px;
  .item {
    &:hover {
      background-color: colors.$GRAY2;
    }
    button {
      padding: 16px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .right {
        width: 100%;
        margin-left: 8px;
        padding-left: 16px;
        // border-left: 1px solid colors.$GRAY3;
      }
      .non_selectable {
        cursor: not-allowed;
      }
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &.selected {
      background-color: colors.$GRAY2;
    }

    .revoke_info_row {
      display: flex;
      justify-content: space-between;
      .revoke_info_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        font-size: 16px;
        color: colors.$GRAY5;
        .content {
          color: colors.$GRAY5;
          text-align: left;
          max-height: 60px;
          overflow-y: scroll;
        }
        .block {
          color: colors.$RED1;
        }
        .allow {
          color: colors.$BLUE4;
        }
      }
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
  .item + .item {
    border-top: 1px solid colors.$GRAY3;
  }
}

.empty_text {
  @include fontSize.spoqaM16;
  color: colors.$GRAY5;
}
