@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.infoBox {
  @include flexbox.flexbox(_, center);
  display: flex;
  height: 34px;
  gap: 40px;
  @include fontSize.spoqaR16;

  .label {
    color: colors.$GRAY5;
    width: 180px;
  }

  .info {
    a {
      text-decoration: underline;
    }
  }

  .link {
    @include flexbox.flexbox(center, center);
    gap: 16px;

    button {
      font-weight: 400;
      border-radius: 8px;
    }

    a.underline {
      text-decoration: underline;
    }
  }
}
