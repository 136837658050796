/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #eff6ff;

  .innerWrapper {
    padding: 16px;
  }

  > img {
    width: 100%;
    padding: 16px 8px 0;
  }
}

.tabWrapper {
  display: flex;
  width: 100%;
  height: 44px;
  margin-bottom: 2px;
  font-size: 16px;
  background-color: colors.$WHITE;

  > div {
    width: 100%;
    font-weight: 400;
    color: colors.$GRAY3;
    border-bottom: 2px solid #d9d9d9;
    @include flexbox.flexbox(center, center);
  }

  :first-child {
    font-weight: 700;
    color: rgb(34, 34, 34);
    border-bottom: 2px solid colors.$GRAY8;
  }
}

.infoIndex {
  padding: 16px 0;
  font-size: 18px;
  font-weight: 700;
}

.infoTopWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.5;
  color: rgb(75, 75, 75);
  white-space: break-spaces;
  background-color: colors.$WHITE;
  border-radius: 10px;

  div {
    color: inherit;
  }

  h4 {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    color: rgb(34, 34, 34);
  }

  small,
  .medication_info {
    font-size: 12px;
    font-weight: 400;
    color: rgb(124, 124, 124);
  }

  .medication_info {
    margin-top: 8px;
  }
}

.collectTarget {
  &Guide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    font-size: 18px;
    font-weight: 700;
    white-space: break-spaces;
  }
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    margin-top: 16px;
    margin-bottom: 12px;

    > div {
      text-align: center;
    }

    .gridItem {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      height: 100%;

      > div {
        border-right: 1px solid #dddddd;
      }

      > p {
        white-space: nowrap;
        color: #4b4b4b;
      }

      // 첫번째
      &:nth-child(3n-2) {
        width: 85px;

        > div {
          display: flex;
          justify-content: flex-start;
        }

        > p {
          margin-left: -24px;
        }
      }

      // 두번째
      &:nth-child(3n-1) {
        width: 110px;

        > div {
          display: flex;
          justify-content: center;
        }
      }

      // 세번째
      &:nth-child(3n) {
        width: 85px;

        > div {
          display: flex;
          justify-content: flex-end;
          border-right: 1px solid transparent;
        }

        > p {
          width: 100%;
          margin-left: 10px;
        }
      }

      // 마지막
      &:last-child {
        > div {
          border-right: 1px solid transparent;
        }
      }
    }

    .emr:nth-child(3n) > p {
      margin-left: -8px;
    }
  }
}

.infoBottomWrapper {
  @extend .infoTopWrapper;

  h4 {
    margin-bottom: 0;
  }

  svg {
    min-width: 56px;
    min-height: 60px;
  }

  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .dataManage {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    > div {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}

.checkDealing {
  @include flexbox.flexbox("center");
  gap: 4px;
  margin-bottom: 12px;
  font-size: 14px;
  color: rgb(124, 124, 124);
}

.dealingButton {
  @include flexbox.flexbox("center");
  width: 328px;
  height: 52px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  color: colors.$WHITE;
  background-color: #4b4b4b;
  border-radius: 16px;
}

.blank {
  height: 60px;
}

dl.privacy {
  display: flex;
  flex-direction: column;
  gap: 14px;

  dt {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  dd {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }
}
