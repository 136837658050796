@use "style/config/constants/colors";

.container {
  input[type="radio"] {
    border-radius: 50%;
    border: 2px solid #bebebe;
    width: 20px;
    height: 20px;
    appearance: none;
    vertical-align: middle;
    margin: 0;
    cursor: pointer;

    &:checked {
      border: 5px solid colors.$BLUE4;
    }
  }
}
