@use "style/config/constants/colors";

.indicator {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #262626, $alpha: 50%);

  @keyframes load {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    content: " ";
    border: 10px solid colors.$GRAY3;
    border-top: 10px solid var(--primary-color);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: load 1s linear infinite;
  }
}
