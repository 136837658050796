@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(center, center);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 84px;
  gap: 8px;
  background-color: colors.$WHITE;
}
