@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(center, center);

  .container {
    position: relative;
    @include flexbox.flexbox(end, center);
    width: 87px;
    height: 28px;
    border-radius: 16px;
    background-color: colors.$GRAY3;
    border: 1px solid colors.$GRAY4;

    .btnBox {
      position: absolute;
      right: -1px;

      &.moveToLeft {
        position: absolute;
        left: -1px;
      }

      width: 60px;
      height: 28px;
      border-radius: 16px;
      color: colors.$WHITE;
      background-color: var(--primary-color);

      button {
        width: 100%;
        height: 100%;
        font-size: 10px;
        font-weight: 500;
        border-radius: 16px;
        color: colors.$WHITE;
        border-color: var(--primary-color);
      }
    }
  }

  .bgc {
    background-color: colors.$WHITE;
  }

  .moveTo {
    @include flexbox.flexbox(start, center);

    button {
      background-color: colors.$BLUE6;
    }
  }
}
