@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(center, center);
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    background: {
      position: center center;
      size: cover;
      image: url(../../../assets/images/pages/login/background-renewal.png);
    }
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  > * {
    width: 1268px;
    height: 738px;
    border-radius: 37px;
  }
}

.loginWrapper {
  background-color: colors.$WHITE;
  @include flexbox.flexbox(center, center);
  position: absolute;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);

  .loginContainer {
    width: 400px;
    height: 100%;
    @include flexbox.flexbox(center, _);
    flex-direction: column;

    .logo {
      svg {
        width: 300px;
        height: 100px;
        margin-left: -40px;
        margin-top: -50px;
      }
    }

    button[type="submit"] {
      background-color: colors.$BLUE4;
    }
  }

  .loginInputBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.title {
  font-size: 38px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 16px;
  white-space: break-spaces;
}

.subtitle {
  font-size: 20px;
  margin-bottom: 24px;
  color: colors.$GRAY6;
}

.optionWrapper {
  @include flexbox.flexbox(between, start);
  margin-top: 16px;
  margin-bottom: 32px;

  label {
    @include fontSize.spoqaR16;
    color: colors.$GRAY7;
  }

  .passwordFind {
    @include fontSize.spoqaR14;
    color: colors.$GRAY5;
    text-decoration: underline;
  }
}
