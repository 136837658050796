@mixin hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0;
  margin: -1px;
  overflow: hidden !important;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
  border: 0;
}

@mixin mobileHidden {
  @media screen and (max-width: 999px) {
    display: none !important;
  }
}

@mixin desktopHidden {
  @media screen and (min-width: 1000px) {
    display: none !important;
  }
}

@mixin noScrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin noBackgroundScrollBar() {
  overflow-y: overlay;

  &::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 10px;
    outline: none;
    box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 15%);
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 4px solid transparent;
    box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 30%);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none;
  }
}
