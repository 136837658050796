@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  @include flexbox.flexbox(_, center);
  @include fontSize.spoqaR16;
  height: 34px;

  label {
    box-sizing: border-box;
    width: 180px;
    margin-right: 40px;
    color: colors.$GRAY5;

    span {
      color: colors.$RED1;
    }
  }

  input {
    @include fontSize.spoqaR14;
    width: calc(100% - 220px);
    height: 34px;
    padding: 7px 16px;
    border: 1px solid colors.$GRAY3;
    border-radius: 8px;

    &::placeholder {
      color: colors.$GRAY4;
    }

    &:focus {
      border: 1px solid var(--primary-color);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.nullStyle {
  input {
    border: 1px solid colors.$RED1;
    background-color: colors.$RED3;
    &::placeholder {
      color: colors.$RED1;
    }

    &:focus {
      border: 1px solid colors.$RED1;
    }
  }
}
