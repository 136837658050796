@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  width: 910px;
  height: 711px;

  position: absolute;
  top: 100%;
  left: 90px;
  z-index: 2;
  padding: 20px;
  background-color: colors.$WHITE;
  box-shadow:
    10px 10px 20px 0px rgba(124, 124, 124, 0.1),
    -10px -10px 20px 0px rgba(124, 124, 124, 0.1);
  border-radius: 8px;

  button {
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #dddddd;
  }

  .title {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 33px;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
  }

  .contentContainer {
    margin: 0 20px;

    table {
      width: 100%;
      background-color: colors.$GRAY1;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;

      tr {
        text-align: center;

        th {
          padding: 10px;
          background-color: colors.$GRAY2;
          font-weight: 700;

          span {
            font-size: 14px;
          }
        }

        td {
          padding: 10px;
          border-bottom: 1px solid colors.$GRAY2;
        }
      }
    }

    .paraphraseTitle {
      margin-top: 16px;
      color: colors.$GRAY5;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    ul {
      padding-left: 20px;

      li {
        list-style-type: disc;
        @include fontSize.spoqaR14;
        color: colors.$GRAY5;
        margin-top: 8px;

        &::marker {
          font-size: 13px;
        }
      }
    }
  }
}
