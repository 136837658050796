@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.container {
  display: flex;
  gap: 16px;

  .info {
    min-width: 195px;
    width: fit-content;

    &:last-of-type {
      margin: 0;
    }

    p {
      &:first-of-type {
        @include fontSize.spoqaR14;
        color: colors.$GRAY6;
        margin-bottom: 4px;
      }

      &:last-of-type {
        @include fontSize.spoqaB28;
        color: var(--primary-color);
      }
    }
  }
}
