@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.header {
  @include flexbox.flexbox(start);
  width: 100%;
  height: 80px;

  > div {
    height: 100%;
    padding: 0 40px;
  }
}

.logo {
  width: 240px;
  padding: 0 !important;
}

.content {
  @include flexbox.flexbox(start, start);
  width: 100%;
  height: calc(100% - 80px);
}

.nav {
  @include flexbox.flexbox(start);
  flex-direction: column;
  width: 240px;
  height: 100%;
  padding: 40px 16px;
  background-color: #222;
}

.navBottom {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-top: auto;
  margin-left: 30px;

  a,
  p {
    padding-left: 6px;
    margin-top: 8px;
    font-size: 12px;
    color: colors.$GRAY4;
    border-left: 1px solid colors.$GRAY4;

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:nth-last-child(3) {
      margin-top: 0;
      border-left: 1px solid #bfbfbf00;
    }
  }

  a {
    text-decoration-line: underline;
  }

  svg {
    width: 120px;
    padding-left: 6px;
    border-left: 2px solid colors.$GRAY4;
  }
}
