@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;

  thead {
    width: 48px;
    @include fontSize.spoqaR12;
    background-color: colors.$GRAY2;

    td {
      padding: 16px 8px;
      white-space: nowrap;
      border-bottom: 1px solid colors.$GRAY3;
    }
  }

  tbody {
    @include fontSize.spoqaR14;

    tr {
      border-bottom: 1px solid colors.$GRAY3;

      td {
        color: colors.$GRAY6;
        white-space: pre-wrap;

        > a {
          color: colors.$GRAY6;
          text-decoration: underline;
        }
      }
    }
  }

  tr {
    height: 48px;

    td {
      text-align: center;
      vertical-align: middle;
    }
  }
}
