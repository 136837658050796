@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.caution {
  width: 100%;
  padding: 24px;
  color: colors.$GRAY6;
  white-space: break-spaces;
  background-color: colors.$GRAY1;
  border-radius: 8px;
  @include fontSize.spoqaR12;

  li {
    list-style: disc;
    margin-left: 24px;
    color: colors.$GRAY7;
  }
}
