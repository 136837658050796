/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.innerPannel {
  background-color: colors.$WHITE;
  border-radius: 8px;
  height: 60px;
  padding: 0 40px;
  margin-bottom: 24px;
}

.dataDownload {
  background-color: colors.$WHITE;
  border-radius: 8px;
  height: 60px;
  padding: 0 40px;
  margin-bottom: 24px;
  @include flexbox.flexbox(between);
  @include fontSize.spoqaB20;
}

.buttonWrapper {
  position: relative;
  display: flex;
  gap: 8px;

  .buttonOverlay {
    position: absolute;
    width: 120px;
    height: 34px;
    border-radius: 8px;
    left: 0;
    gap: 10px;
    @include flexbox.flexbox(center, center);
    @include fontSize.spoqaR12;
    color: colors.$WHITE;
    background-color: rgba(0, 0, 0, 50%);
    border: 1px solid rgba(222, 222, 222, 50%);
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
  gap: 15px;

  > div {
    @include fontSize.spoqaR16;
    color: colors.$GRAY7;
  }
}

.loadlingSpinner::before {
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  border-radius: 20px;
  animation: loadlingSpinner 1s infinite linear reverse;
}

.hidden {
  display: none !important;
}
