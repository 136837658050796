@use "style/pages/market/marketMixins" as market;
@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.subTitle {
  @include market.subTitle;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    margin-left: 8px;
  }
}

.dataWrapper {
  @include market.dataWrapper;
  width: 600px;
}

.list {
  display: flex;
  align-items: center;
  gap: 16px;

  .label {
    @include fontSize.spoqaR16;
    color: colors.$GRAY5;
    width: 120px;
  }
}
