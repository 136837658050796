@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.title {
  @include flexbox.flexbox(between, center);
  @include fontSize.spoqaB18;
}

.chart {
  height: 300px;
}

.statisticsTop {
  @include flexbox.flexbox(between, start);

  .dateRangeBox {
    @include flexbox.flexbox(_, center);
    gap: 16px;

    span {
      @include fontSize.spoqaM14;
      color: colors.$GRAY5;
    }
  }
}

.statistics {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 148px;
}

.spinner {
  @keyframes load {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }

  &::before {
    position: absolute;
    top: 70%;
    left: 50%;
    width: 80px;
    height: 80px;
    content: " ";
    border: 10px solid colors.$GRAY3;
    border-top: 10px solid var(--primary-color);
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: load 1s linear infinite;
  }
}
