/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  .dateBox {
    display: flex;
    gap: 8px;
    align-items: center;

    span {
      color: colors.$GRAY5;
      @include fontSize.spoqaM14;
      margin-right: 8px;
    }

    input[type="date"] {
      width: 215px;
      height: 36px;
      padding-right: 10px;
      padding-left: 15px;
      font-size: 12px;
      color: colors.$GRAY4;
      border: 1px solid colors.$GRAY3;
      border-radius: 8px;

      &:read-only {
        background-color: colors.$GRAY3;
      }

      &:disabled {
        background: #f5f5f5;
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      width: 10px;
      height: 10px;
      cursor: pointer;
      background: url(/assets/images/calendar.png);
      background-size: cover;
    }

    .separator {
      margin: 0 5px;
    }
  }

  .checkBox {
    margin-left: 20px;
  }
}

.warning {
  margin-bottom: 23px;
  color: colors.$RED1;
}

.searchBtn {
  width: 100px;
  height: 36px;
  margin-left: 10px;
}

.checked {
  color: #262626 !important;
}

.searchClearBtn {
  position: absolute;
  @include flexbox.flexbox(center, center);
  @include fontSize.spoqaR14;
  top: calc(100% + 8px);
  right: 0px;
  gap: 4px;
  color: colors.$GRAY4;
}
