@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.formatWrapper {
  position: relative;
  width: fit-content;
  height: 34px;

  .mockInput {
    @include fontSize.spoqaR14;
    position: absolute;
    z-index: 0.5;
    padding: 0 16px;
    border: 1px solid colors.$GRAY3;
    border-radius: 8px;
  }

  .format {
    @include fontSize.spoqaR14;
    position: absolute;
    left: 175px;
    display: inline;
    line-height: 34px;
    color: colors.$GRAY7;
  }

  .formatting {
    padding-right: 46px;
  }
}

.price {
  &Button {
    @include fontSize.spoqaM14;
    font-weight: 500;
    width: fit-content;
    height: 34px;
    padding: 0 16px;
    margin: 0 8px 16px 0;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
  }

  &Input {
    @include fontSize.spoqaR14;
    line-height: 34px;
    width: 220px;
    height: 34px;
    position: absolute;
    padding: 7px 16px;
    z-index: 0;
    color: colors.$GRAY7;
    text-align: right;
    border: none;

    &Label {
      margin-left: 224px;
    }

    &::placeholder {
      color: colors.$GRAY4;
      position: absolute;
      top: 8px;
      left: 27px;
    }
  }
}

.note {
  margin-left: 8px;
  @include fontSize.spoqaR16;
  color: colors.$GRAY5;
}
