/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;

  .headerTop {
    height: 90px;
    display: flex;
    gap: 12px;
    align-items: center;
    padding-left: 8px;
    padding-top: 40px;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #dddddd;
  }
}

.progress {
  display: flex;
  gap: 4px;
  justify-content: space-around;
  padding: 20px 16px;
  height: 80px;

  &Bar {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
    font-size: 14px;

    &:first-child {
      div {
        background-color: #6484f1;
      }

      p {
        font-weight: 700;
        color: rgb(100, 132, 241);
      }
    }

    > div {
      display: flex;
      width: 100%;
      height: 10px;
      background-color: colors.$GRAY3;
      border-radius: 8px;
    }

    > p {
      font-weight: 400;
      color: colors.$GRAY3;
    }
  }

  &Label {
    display: flex;
    justify-content: space-around;
  }
}

.bannerImage {
  width: 100%;
  height: 208px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 22px;

  .title {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.2;
  }

  .point {
    font-size: 24px;
    font-weight: 800;
    color: colors.$RED3;
  }

  .period {
    font-size: 12px;
    font-weight: 400;
    color: colors.$GRAY5;
  }

  .mockBtn {
    @include flexbox.flexbox(center, center);
    width: 100%;
    height: 52px;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 500;
    color: colors.$WHITE;
    background-color: #4b4b4b;
    border-radius: 22px;
  }
}
