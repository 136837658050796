@use "style/pages/market/marketMixins" as market;
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 20px;
}

.subTitle {
  @include market.subTitle;
  margin-bottom: 8px;
}

.titleWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 34px;

  .dataType {
    @include fontSize.spoqaB16;
    color: var(--primary-color);
  }

  .titleDirectInput {
    width: 600px;
    height: 34px;
    border: 1px solid #d9d9d9;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;

    &::placeholder {
      color: colors.$GRAY4;
    }
  }
}
