@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #262626, $alpha: 50%);
}

.wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  width: 518px;
  height: 668px;
  background-color: colors.$WHITE;
  transform: translateX(-50%) translateY(-50%);
  padding-bottom: 43px;

  .header {
    @include flexbox.flexbox(between, center);
    width: 100%;
    height: 59px;
    background-color: colors.$GRAY8;
    padding: 17px 24px;

    div {
      color: colors.$WHITE;
      font-size: 20px;
      font-weight: 500;
    }

    button {
      font-size: 24px;
      color: colors.$WHITE;
    }
  }

  h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 50px 0;
    text-align: center;
  }

  .content {
    width: 100%;
    height: calc(100% - 200px);
    padding: 0 24px;

    .title {
      font-size: 24px;
      font-weight: 500;
      white-space: pre-wrap;
    }

    hr {
      margin: 20px 0 32px;
    }

    .disagreeContent {
      @include flexbox.flexbox(center, center);
      flex-direction: column;
      white-space: pre-wrap;
      gap: 22px;
      margin-bottom: 116px;

      svg {
        width: 56px;
        height: 56px;
        margin-top: 7px;
      }

      div {
        font-size: 22px;
        font-weight: 500;
        line-height: 40px;
        color: colors.$GRAY7;
      }
    }

    .itemBox {
      @include flexbox.flexbox(_, start);
      margin-bottom: 35px;

      div {
        &:first-child {
          @include flexbox.flexbox(_, start);
          @include fontSize.spoqaB20;
          width: 74px;
          margin-right: 35px;
          white-space: pre-wrap;
        }
      }

      .item {
        color: colors.$GRAY7;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        white-space: pre-wrap;
      }
    }
  }
}

.checkStyle {
  @include flexbox.flexbox(start, _);
  margin-top: 60px;

  label {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
  }

  a {
    border-bottom: 1px solid colors.$GRAY5;
    color: colors.$GRAY5;
  }
}

.buttonBox {
  @include flexbox.flexbox(center, center);
  gap: 22px;
  margin-top: 24px;

  button {
    @include fontSize.spoqaB24;
    width: 224px;
    height: 64px;
    color: colors.$GRAY7;
    border: 1px solid colors.$GRAY7;
    border-radius: 16px;
  }

  .primary {
    background: colors.$GRAY7;
    color: colors.$WHITE;
  }
}
