/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(start, center);
  @include fontSize.spoqaR16;
  width: 120px;
  color: colors.$GRAY5;
  margin-right: 16px;
}
