@use "style/config/constants/colors";

.container {
  margin-top: 24px;
}
.panel {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.input_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.error_input {
  border-color: colors.$RED1 !important;
}

.did_revoke_container {
  display: flex;
  justify-content: flex-end;
}
