@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.pwInputBox {
  @include flexbox.flexbox(center, center);
  flex-direction: column;
  position: absolute;
  top: 84px;
  gap: 8px;

  input {
    @include fontSize.spoqaR14;
    width: 280px;
    height: 40px;
    padding: 8px 16px;
    border: 1px solid colors.$GRAY3;
    border-radius: 8px;

    &::placeholder {
      color: colors.$GRAY4;
    }
  }
}
