@use "style/config/mixins/fontSize";
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";

.title {
  @include flexbox.flexbox(start, center);
  @include fontSize.spoqaB20;
  height: 60px;
  padding: 0 40px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: colors.$WHITE;
}
