@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/flexbox";

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .buttonBox {
    @include flexbox.flexbox(center, center);
    gap: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 95px;
    background-color: colors.$WHITE;

    .back {
      color: colors.$GRAY5;
      border: 1px solid colors.$GRAY3;
      font-weight: 500;
    }
  }
}
