@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(start, start);
  gap: 16px;
  overflow-x: auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 195px;
  width: fit-content;
  height: 100px;
  padding: 15px 24px;
  background-color: #e7e9ee;
  border-radius: 6px;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    @include fontSize.spoqaR14;
    color: colors.$GRAY5;
  }

  img {
    width: 24px;
    height: 24px;
  }

  + p {
    @include fontSize.spoqaB20;
  }
}
