@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  display: flex;
  flex-direction: column;
  background-color: colors.$WHITE;
  border-radius: 0 0 8px 8px;
  margin-bottom: 24px;

  .line {
    height: 4px;
    width: 100%;
    background-color: var(--primary-color);
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px;
  }
}
