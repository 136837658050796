@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: colors.$WHITE;
  border-radius: 12px;
  gap: 15px;
  padding: 43px 32px 30px 32px;

  .topContainer {
    display: flex;
    justify-content: space-between;

    .searchContainer {
      .datepickerContainer {
        display: flex;
        gap: 10px;
      }
    }
  }

  .btnBox {
    @include flexbox.flexbox(end);
    width: 100%;
    margin-top: 34px;
    margin-bottom: 8px;
  }

  .resetContainer {
    display: flex;
    height: 40px;
    justify-content: flex-end;

    .searchClearBtn {
      gap: 6px;
      color: #a7a7a7;
      @include fontSize.spoqaR14;
      @include flexbox.flexbox("center", "center");
    }
  }
}
