@use "style/config/mixins/fontSize";
@use "style/config/constants/colors";
@use "style/config/mixins/position";
@use "style/config/mixins/visual";
@use "style/config/mixins/flexbox";

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  max-width: 1040px;
  min-height: 476px;
  max-height: 700px;
  padding: 41px 50px;
  overflow: auto;
  border: 1px solid colors.$GRAY3;
  border-radius: 8px;
  @include visual.noScrollbar;

  .radioWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    > img {
      width: 280px;
      height: 170px;
      cursor: pointer;
    }

    .radio {
      @include flexbox.flexbox(_, center);
      width: 280px;
      gap: 8px;

      > input[type="radio"] {
        position: relative;
        width: 20px;
        height: 20px;
        background-color: colors.$WHITE;
        border: 1px solid colors.$GRAY3;
        border-radius: 50%;

        &::after {
          @include position.middleBox;
          width: 10px;
          height: 10px;
          content: "";
          background-color: colors.$GRAY4;
          border-radius: 50%;
        }

        &:checked {
          background-color: colors.$WHITE;

          &::after {
            background-color: var(--primary-color);
          }
        }
      }

      > label {
        @include fontSize.spoqaR16;
        cursor: pointer;
      }
    }
  }
}
