@use "style/config/constants/colors";

.textarea {
  width: 100%;
  max-width: 815px;
  min-height: 136px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 8px 16px;
  color: rgb(42, 42, 42);
  resize: none;
  font-size: 14px;
  line-height: 24px;

  &:focus {
    border-color: colors.$BLUE4;
  }
  &::placeholder {
    color: #bfbfbf;
  }
}
