@use "style/config/mixins/fontSize";
@use "style/config/constants/colors";
@use "style/config/mixins/position";

.wrapper {
  display: flex;
  gap: 10px;

  .radio {
    display: flex;
    align-items: center;
    gap: 8px;

    > input[type="radio"] {
      position: relative;
      width: 20px;
      height: 20px;
      cursor: pointer;
      background-color: colors.$WHITE;
      border: 1px solid colors.$GRAY4;
      border-radius: 50%;

      &::after {
        @include position.middleBox;
        width: 10px;
        height: 10px;
        content: "";
        background-color: colors.$GRAY4;
        border-radius: 50%;
      }

      &:checked {
        background-color: colors.$WHITE;

        &::after {
          background-color: var(--primary-color);
        }
      }
      &:disabled + label {
        color: colors.$GRAY4;
      }
    }

    > label {
      @include fontSize.spoqaR16;
      cursor: pointer;
    }
  }
}
