@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(center, center);

  .chip {
    @include flexbox.flexbox(center, center);
    @include fontSize.spoqaR12;
    width: fit-content;
    height: 24px;
    padding: 4px 8px;
    border-radius: 4px;
  }
}

// 종료
.ended {
  color: colors.$RED1;
  border: 1px solid colors.$RED2;
  background-color: colors.$RED3;
}

// 임시저장, 요청
.temporary,
.request {
  color: colors.$GRAY8;
  border: 1px solid colors.$GRAY6;
  background-color: colors.$GRAY3;
}

// 승인요청, 충전
.pending,
.charge {
  color: colors.$MINT1;
  border: 1px solid colors.$MINT2;
  background-color: colors.$MINT3;
}

// 요청취소,
.cancel {
  color: colors.$ORANGE1;
  border: 1px solid colors.$ORANGE2;
  background-color: colors.$ORANGE3;
}

// 반려, 사용
.reject,
.use {
  color: colors.$PINK1;
  border: 1px solid colors.$PINK2;
  background-color: colors.$PINK3;
}

// 진행중
.inProgress {
  color: colors.$BLUE4;
  border: 1px solid colors.$BLUE3;
  background-color: colors.$BLUE1;
}

// 예정
.notStarted {
  color: colors.$YELLOW1;
  border: 1px solid colors.$YELLOW2;
  background-color: colors.$YELLOW3;
}

// 환급
.refund {
  color: colors.$GREEN1;
  border: 1px solid colors.$GREEN2;
  background-color: colors.$GREEN3;
}

//  철회
.revoked {
  color: colors.$PURPLE1;
  border: 1px solid colors.$PURPLE2;
  background-color: colors.$PURPLE3;
}
