@use "style/pages/market/marketMixins" as market;
@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.subTitle {
  @include market.subTitle;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    margin-left: 8px;
  }
}

.dataWrapper {
  @include market.dataWrapper;
  width: 600px;
}

.list {
  display: flex;
  align-items: center;
  gap: 16px;

  .label {
    @include fontSize.spoqaR16;
    color: colors.$GRAY5;
    width: 120px;
  }

  .inputWrapper {
    @include fontSize.spoqaB16;

    input {
      width: 220px;
      height: 34px;
      padding: 5px 16px;
      @include fontSize.spoqaR14;
      border: 1px solid colors.$GRAY3;
      border-radius: 8px;
      margin-right: 8px;
      text-align: left;

      &::placeholder {
        color: colors.$GRAY4;
      }

      &:focus {
        border: 1px solid var(--primary-color);
      }
    }
  }
}
