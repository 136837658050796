@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/flexbox";

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #262626, $alpha: 50%);
  z-index: 3;
}

.content {
  @include flexbox.flexbox(start, center);
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 440px;
  height: 260px;
  padding: 48px 40px 32px 40px;
  border-radius: 16px;
  background-color: colors.$WHITE;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.15);

  p {
    @include fontSize.spoqaB18;
  }

  .description {
    @include fontSize.spoqaR16;
    @include flexbox.flexbox(center, center);
    text-align: center;
    color: colors.$GRAY6;
    white-space: pre-wrap;
    height: calc(100% - 40px);
  }

  .isTitle {
    height: calc(100% - 82px);
  }
}

.buttonBox {
  @include flexbox.flexbox(center, center);
  position: absolute;
  bottom: 32px;
  gap: 8px;
}
