@use "style/pages/market/marketMixins" as market;
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.subTitle {
  @include market.subTitle;
}

.titleWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}
