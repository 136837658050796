.wrapper {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
}

.tab_box {
  display: flex;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #bfbfbf;
  background: #fafafa;

  &:nth-child(1) {
    border-radius: 12px 0 0;
  }

  &:nth-child(2) {
    border-radius: 0 12px 0 0;
  }

  &.active {
    font-weight: 800;
    color: #262626;
    background: #ffffff;
  }
}

.pannel {
  min-height: 100px;
  padding: 32px;
}
