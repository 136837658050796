@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/flexbox";

.wrapper {
  @include flexbox.flexbox(center, center);
  flex-direction: column;
  gap: 8px;

  input {
    width: 400px;
    height: 40px;
    border: 1px solid colors.$GRAY3;
    border-radius: 8px;
    @include fontSize.spoqaR14;
    padding: 7px 16px;

    &::placeholder {
      color: colors.$GRAY4;
    }

    &:focus {
      border: 1px solid var(--primary-color);
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 20%);
    }

    // remove autofill bgc
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      box-shadow: 0 0 0 1000px white inset;
    }
  }

  .errStyle {
    outline: 1px solid colors.$RED1;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .errorMsg {
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: colors.$RED1;
  }
}
