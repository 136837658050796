@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";

.button {
  @include fontSize.spoqaR12;
  width: 54px;
  height: 24px;
  margin-right: 8px;
  background: colors.$GRAY2;
  border: 1px solid colors.$GRAY3;
  border-radius: 8px;
}
