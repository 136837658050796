/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/visual";

.wrapper {
  position: fixed;
  top: 120px;
  right: -360px;
  width: 360px;
  height: 75vh;
  background-color: white;
  box-shadow: 0 8px 36px 0 rgba(0, 0, 0, 0.16);
  transition: transform 0.3s ease-out;

  &Open {
    transition: transform 0.3s ease-out;
    transform: translateX(-100%);
  }
}

.innerWrapper {
  @include visual.noBackgroundScrollBar;
  height: 100%;
  overflow: auto;
}

.sidebarOpenBtn {
  position: absolute;
  top: 242px;
  left: -32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 90px;
  background-color: colors.$WHITE;
  border: 1px solid colors.$GRAY4;
  border-radius: 4px 0 0 4px;
  border-right: none;
  box-shadow: 1px 2px 4px rgba(159, 159, 159, 25%);
}

.closeArrow {
  margin-left: 3px;
  transform: scaleX(-1);
}
