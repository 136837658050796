@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 29px;

  .subTitle {
    @include fontSize.spoqaB16;
  }

  input {
    width: 383px;
  }

  .dropdown {
    display: flex;
    gap: 40px;

    div {
      @include flexbox.flexbox(_, center);
      width: 180px;
      height: 34px;
      color: colors.$GRAY5;

      span {
        color: colors.$RED1;
      }
    }
  }
}
