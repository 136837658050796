@use "style/config/constants/colors";

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 8px;
  background-color: #fff;
}
