@use "style/config/constants/colors";
@use "style/config/mixins/position";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/visual";
@use "style/config/mixins/flexbox";

.wrapper {
  position: relative;
  width: 328px;

  button[name="selected"] {
    width: 100%;
    height: 34px;
    padding-right: 28px;
    font-size: 14px;
    text-align: start;
    border-radius: 8px;
    background-color: colors.$WHITE;
    color: colors.$GRAY6;

    &:disabled {
      color: colors.$GRAY5;
      background: #f5f5f5;
    }
  }

  .placeholder {
    color: colors.$GRAY5 !important;

    &::before {
      stroke: colors.$GRAY3 !important;
    }
  }

  ul.option {
    @include visual.noBackgroundScrollBar;
    position: absolute;
    width: 100%;
    top: 110%;
    z-index: 1;
    overflow: auto;
    border-radius: 8px;
    background: colors.$WHITE;
    border: 1px solid colors.$GRAY4;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);

    li > button {
      width: 100%;
      padding: 8px 24px;
      text-align: start;
      word-break: keep-all;
      @include fontSize.spoqaR14;

      .optionTxtPosition {
        text-align: center;
      }

      &:hover {
        color: colors.$WHITE;
        background: var(--primary-color);
      }
    }
  }
}

// down bracket icon
.primary {
  button[name="selected"] {
    border: 1px solid colors.$GRAY3;
    @include fontSize.spoqaM14;

    &::before {
      @include position.posCenterY;
      height: 60%;
      right: 0px;
      content: url(../../../assets/svgs/icon/dropdown-bracket.svg);
      transition: 0.5s;
    }
  }
}

// full triangle icon
.secondary {
  button[name="selected"] {
    height: 34px;
    border: 1px solid colors.$GRAY5;
    padding: 7px 16px;
    border-radius: 8px;
    color: colors.$GRAY8;

    &::before {
      @include position.posCenterY;
      right: 16px;
      height: 50%;
      color: colors.$GRAY5;
      content: url(../../../assets/svgs/icon/down-triangle.svg);
    }
  }
}

// border color 연하게
.soft {
  > button {
    border-color: colors.$GRAY3 !important;
  }

  .placeholder {
    color: colors.$GRAY4 !important;
  }
}

// border none
.none {
  > button {
    border: none !important;
    text-align: right !important;
  }
}
