@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  @include flexbox.flexbox(_, center);
  @include fontSize.spoqaR14;
  width: 100%;
  height: 100%;
  color: colors.$GRAY6;
  gap: 40px;

  > label {
    @include fontSize.spoqaR16;
    width: 290px;
    color: colors.$GRAY5;

    span {
      color: colors.$RED1;
    }
  }
}
