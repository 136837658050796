@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.button {
  @include flexbox.flexbox;
  width: fit-content;
  height: 36px;
  padding: 8px 12px;
  font-size: 12px;
  color: colors.$GRAY5;
  background-color: colors.$WHITE;
  border: 1px solid colors.$GRAY5;
  border-radius: 8px;
}

.toggleOn {
  color: var(--primary-color);
  background-color: #e6f7ff;
  border: 1px solid var(--primary-color);
}
