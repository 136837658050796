@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  flex-direction: column;
  background-color: colors.$WHITE;
  border-radius: 0 0 8px 8px;

  .line {
    height: 4px;
    width: 50%;
    background-color: var(--primary-color);
  }

  .contentContainer {
    @include fontSize.spoqaR16;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px;

    .itemBox {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .inputBox {
        display: flex;
        align-items: center;
        gap: 16px;

        > span {
          width: 120px;
          @include fontSize.spoqaR16;
          color: colors.$GRAY5;
          white-space: nowrap;
        }
      }

      .infoBox {
        width: 100%;
        background-color: colors.$GRAY1;
        border-radius: 8px;
        padding: 24px;

        div {
          @include fontSize.spoqaR12;
          color: colors.$GRAY7;
        }
      }
    }
  }
}

.dropdownLabel {
  @include fontSize.spoqaR14;
  color: colors.$GRAY8;

  > span {
    color: colors.$GRAY4;
    margin-left: 15px;
  }
}

.modalText {
  @include fontSize.spoqaR16;
  color: colors.$GRAY6;
  white-space: pre-wrap;

  span {
    color: colors.$GRAY6;
    font-weight: 700;
  }
}
