@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(start, start);
  gap: 80px;
}

.info {
  min-width: fit-content;

  p {
    &:first-of-type {
      @include fontSize.spoqaR14;
      color: colors.$GRAY6;
      margin-bottom: 4px;
    }

    &:last-of-type {
      @include fontSize.spoqaB28;
      color: var(--primary-color);
    }
  }
}
