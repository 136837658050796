@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.button {
  @include flexbox.flexbox(center, center);
  @include fontSize.spoqaB14;
  border-radius: 12px;
  white-space: nowrap;

  // mode
  &.default {
    color: #fff;
    background-color: var(--primary-color);
  }

  &.bgBlack {
    color: #fff;
    background: colors.$GRAY7;
  }

  &.lineBlue {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid var(--primary-color);
  }

  &.lineGray {
    color: colors.$GRAY5;
    background: #fff;
    border: 1px solid colors.$GRAY5;
  }

  // size
  &.long {
    @include fontSize.spoqaB16;
    width: 400px;
    height: 52px;
    padding: 16px;
    border-radius: 16px;
  }

  &.medium {
    @include fontSize.spoqaB16;
    width: 196px;
    height: 52px;
    padding: 16px;
    border-radius: 16px;
  }

  &.normal {
    width: 140px;
    height: 40px;
    padding: 10px;
  }

  &.short {
    @include fontSize.spoqaR12;
    width: 120px;
    height: 34px;
    padding: 7px 10px;
    border-radius: 8px;
  }

  &.small {
    @include fontSize.spoqaR12;
    width: 80px;
    height: 34px;
    padding: 7px 8px;
    border-radius: 8px;
  }

  &:disabled {
    color: colors.$GRAY4;
    background-color: colors.$GRAY2;
    border: 1px solid colors.$GRAY4;
  }
}
