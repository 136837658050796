@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.backButton {
  display: block;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  color: colors.$GRAY5;
  text-decoration: underline;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 200px;
  padding-bottom: 100px;
  background-color: white;
  border-radius: 24px;
}

.content {
  margin-bottom: 40px;

  p {
    color: colors.$GRAY5;

    &:first-of-type {
      font: {
        weight: 500;
        size: 96px;
        height: 120px;
      }
      margin-bottom: 18px;
    }

    &:last-of-type {
      font-size: 18px;
      font-weight: 400;
      line-height: 34px;
      white-space: pre-wrap;
    }
  }
}
