@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.button {
  @include flexbox.flexbox(center, center);
  @include fontSize.spoqaR14;
  box-sizing: border-box;
  min-width: 58px;
  width: fit-content;
  height: 34px;
  white-space: nowrap;
  border-radius: 8px;
  padding: 9px 16px;
  margin: auto;
}

.color {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: colors.$BLUE1;
}

.line {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: colors.$WHITE;
}

.default {
  color: colors.$GRAY5;
  border: 1px solid colors.$GRAY5;
  background-color: colors.$WHITE;
}
