@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";

.section {
  @include flexbox.flexbox(between, center);
  width: 100%;
  height: fit-content;
  background-color: colors.$WHITE;
  padding: 40px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.buttonWrapper {
  @include flexbox.flexbox(end);
  button {
    margin: 0;
  }
}
