@use "style/pages/market/marketMixins" as market;

.subTitle {
  @include market.subTitle;
}

.dataWrapper {
  @include market.dataWrapper;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
