@use "style/config/mixins/fontSize";
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";

.wrapper {
  @include flexbox.flexbox(center, center);
  gap: 8px;

  .checkBox {
    @include flexbox.flexbox(center, center);
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid colors.$GRAY4;
    background-color: colors.$WHITE;

    input[type="checkbox"] {
      position: absolute;
      cursor: pointer;
      width: 110%;
      height: 110%;
    }

    svg {
      width: 15px;
      height: 15px;
      stroke: colors.$GRAY4;
    }

    &.active {
      background: var(--primary-color);
      border-color: var(--primary-color);
      background-color: var(--primary-color);

      svg {
        stroke: colors.$WHITE;
      }
    }
  }

  .square {
    border-radius: 4px;
    cursor: pointer;
  }

  .circle {
    border-radius: 50%;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  label {
    @include fontSize.spoqaR16;
    cursor: pointer;
  }
}
