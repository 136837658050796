@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .pwContainer {
    @include flexbox.flexbox(_, center);
    gap: 40px;

    button {
      @include fontSize.spoqaR12;
      margin: 0;
    }

    .label {
      width: 180px;
      @include fontSize.spoqaR16;
      color: colors.$GRAY5;
    }
  }
}
