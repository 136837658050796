@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(center);
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    content: "";
    background: {
      position: center center;
      size: cover;
      image: url(../../../assets/images/pages/login/background-renewal.png);
    }
  }

  > form {
    @include flexbox.flexbox(center, center);
    position: absolute;
    width: 1268px;
    height: 738px;
    border-radius: 37px;
    background-color: colors.$WHITE;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);

    .container {
      @include flexbox.flexbox(center, _);
      flex-direction: column;
      width: 400px;
      height: 100%;

      a {
        svg {
          width: 300px;
          height: 100px;
          margin-left: -40px;
          margin-top: -50px;
        }
      }

      .title {
        @include fontSize.spoqaB28;
        margin-bottom: 16px;
      }

      .subtitle {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 24px;
        color: colors.$GRAY6;
        white-space: break-spaces;
      }

      .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 32px;
      }

      .btnBox {
        display: flex;
        gap: 8px;
      }
    }
  }
}
