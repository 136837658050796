@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  @include flexbox.flexbox(between, start);
  margin-bottom: 32px;

  .total {
    @include flexbox.flexbox(_, center);
    @include fontSize.spoqaR14;
    color: colors.$GRAY4;
    height: 34px;

    span {
      color: colors.$GRAY6;
      font-weight: 700;
    }
  }
}
