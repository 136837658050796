/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  margin-top: 34px;
  border-bottom: 10px solid #eff6ff;
}

.bannerImage {
  width: 100%;
  height: 208px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px;

  .title {
    min-height: 64px;
    padding: 16px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    border-bottom: 1px solid #dddddd;
  }

  .company {
    height: 1rem;
    font-size: 14px;
    font-weight: 400;
    color: rgb(124, 124, 124);
  }

  .mockButtons {
    display: flex;
    gap: 4px;
    padding-top: 16px;

    > div {
      padding: 6px 7px;
      font-size: 12px;
      color: rgb(124, 124, 124);
      background-color: #f4f4f4;
      border-radius: 8px;
      display: flex;
      white-space: nowrap;
    }

    .highlight {
      color: colors.$WHITE;
      background-color: #fc596c;
    }
  }
}
