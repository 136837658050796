@use "style/config/constants/colors";
@use "style/config/mixins/position";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  flex-direction: column;

  .topBox {
    display: flex;
    gap: 8px;

    .inputBox {
      position: relative;

      img {
        @include position.posCenterY;
        left: 16px;
      }

      input {
        width: 330px;
        height: 34px;
        padding: 7px 16px;
        padding-left: 44px;
        border-radius: 8px;
        border: 1px solid colors.$GRAY3;
        @include fontSize.spoqaR14;

        &::placeholder {
          color: colors.$GRAY4;
        }
      }

      .withoutIconStyle {
        width: 220px;
        padding-left: 16px;
      }
    }
  }

  .resetContainer {
    @include flexbox.flexbox(end);
    position: relative;

    .searchClearBtn {
      @include flexbox.flexbox(center, center);
      @include fontSize.spoqaR14;
      position: absolute;
      color: colors.$GRAY4;
      top: 8px;
      gap: 4px;
    }
  }
}
