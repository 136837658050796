@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.line {
  width: 100%;
  height: 1px;
  background-color: colors.$GRAY3;
}

.text {
  @include fontSize.spoqaR16;
}

.pointText {
  color: var(--primary-color);
  font-weight: 700;
  margin-right: 3px;
}

.pointWrapper > p {
  @include fontSize.spoqaB18;
  margin-bottom: 24px;
}
