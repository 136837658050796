/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

button[id="route"] {
  @include fontSize.spoqaR14;
  color: colors.$GRAY6;
  text-decoration: underline;
}

.tableHeader {
  @include flexbox.flexbox(between, center);
  height: 34px;
  margin-bottom: 24px;

  &Search {
    @include flexbox.flexbox(_, center);
    gap: 16px;

    > span {
      font-size: 14px;
      font-weight: 500;
      color: colors.$GRAY6;
      white-space: nowrap;
    }
  }
}

.buttonWrapper {
  @include flexbox.flexbox(end);
  width: 100%;
  margin-top: 5px;
}
