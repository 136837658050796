@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(between);
  margin-bottom: 32px;
}

.searchWrapper {
  @include flexbox.flexbox(start);

  span {
    @include fontSize.spoqaM14;
    color: colors.$GRAY5;
    margin-right: 16px;
  }
}
