@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  width: 100%;
  display: flex;
  position: relative;

  .label {
    color: colors.$GRAY6;
    @include fontSize.spoqaR14;
  }

  .donutChart {
    position: absolute;
    top: -20px;
    left: 40px;
  }
}
