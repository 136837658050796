@use "style/pages/market/marketMixins" as market;
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.innerBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
}

.subTitle {
  @include market.subTitle;
}

.rewardPointWrapper {
  @include flexbox.flexbox(_, center);
  gap: 44px;

  .rewardInputWrapper {
    @include flexbox.flexbox(_, center);
    font-weight: 700;

    input[type="text"] {
      width: 220px;
      height: 34px;
      padding: 5px 16px;
      margin-right: 8px;
      margin-left: 16px;
      @include fontSize.spoqaR14;
      color: colors.$GRAY6;
      border: 1px solid colors.$GRAY3;
      border-radius: 8px;
      text-align: right;

      &::placeholder {
        font-weight: 400;
        color: colors.$GRAY4;
      }

      &:focus {
        border: 1px solid var(--primary-color);
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.peopleInputWrapper {
  @include fontSize.spoqaB16;

  input {
    width: 220px;
    height: 34px;
    padding: 5px 16px;
    @include fontSize.spoqaR14;
    border: 1px solid colors.$GRAY3;
    border-radius: 8px;
    margin-right: 8px;
    text-align: right;

    &::placeholder {
      color: colors.$GRAY4;
    }

    &:focus {
      border: 1px solid var(--primary-color);
    }
  }
}

.managePointWrapper {
  @include flexbox.flexbox(start, center);
  @include fontSize.spoqaR16;
  color: colors.$GRAY5;
  gap: 16px;

  button {
    @include fontSize.spoqaR12;
    border-radius: 8px;
  }
}
