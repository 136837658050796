@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/flexbox";

.errorModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #262626, $alpha: 50%);
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  width: 440px;
  height: 260px;
  padding: 48px 40px 32px 40px;
  background-color: colors.$WHITE;
  border-radius: 16px;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.15);

  div {
    @include fontSize.spoqaB18;
  }

  p {
    @include fontSize.spoqaR16;
    @include flexbox.flexbox(center, center);
    text-align: center;
    color: colors.$RED1;
    white-space: pre-wrap;
    height: calc(100% - 40px);
  }

  .isTitle {
    height: calc(100% - 82px);
  }

  button {
    position: absolute;
    bottom: 32px;
  }
}
