@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;
  align-items: center;
  @include fontSize.spoqaR16;
  height: 34px;

  .label {
    color: colors.$GRAY5;
    width: 180px;
  }

  a {
    text-decoration: underline;
  }
}
