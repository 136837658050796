/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.wrapper {
  display: flex;
  flex-direction: column;

  .innerWrapper {
    img {
      width: 100%;
      height: 120px;
    }

    .emptyAdImg {
      width: 100%;
      height: 120px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include fontSize.spoqaB18;
    }
  }
}

.tabWrapper {
  display: flex;
  width: 100%;
  height: 45px;
  margin-bottom: 2px;
  font-size: 16px;
  background-color: colors.$WHITE;

  > div {
    width: 100%;
    font-weight: 400;
    color: #d9d9d9;
    border-bottom: 3px solid #d9d9d9;
    @include flexbox.flexbox(center, center);
  }

  :first-child {
    font-weight: 700;
    color: rgb(34, 34, 34);
    border-bottom: 3px solid colors.$GRAY8;
  }
}
