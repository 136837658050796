@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  @include flexbox.flexbox(_, center);

  input {
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 16px;
    border: 1px solid colors.$GRAY3;
    border-radius: 8px;
    @include fontSize.spoqaR14;

    &::placeholder {
      color: colors.$GRAY4;
    }

    &:focus {
      border: 1px solid var(--primary-color);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
