@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.wrapper {
  display: flex;
}

.targetWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.subTitle {
  @include market.subTitle;
}
