// Global styles
@import "base/reset";
@import "base/more";
@import "base/fonts";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #e2e6f0;
}

#root {
  height: 100%;
}

#root,
#modal {
  min-width: 1400px;
}

*,
*::before,
*::after {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  color: #222;
}

body,
button,
input,
textarea {
  font-family: "Spoqa Han Sans Neo", sans-serif;
  color: #222;
}
