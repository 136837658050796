@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.contentBox {
  @include flexbox.flexbox(center, _);
  flex-direction: column;
  gap: 16px;

  .title {
    @include fontSize.spoqaB18;
    margin-bottom: 8px;
  }

  .description {
    @include fontSize.spoqaR16;
  }

  .inputBox {
    @include flexbox.flexbox(start, start);
    margin-bottom: 40px;
  }
}
