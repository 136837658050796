@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox("center", "center");
  width: 100%;
  height: 100%;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  white-space: break-spaces;
}
