@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  background-color: colors.$WHITE;
  border-radius: 8px;

  .tabContainer {
    width: 100%;
    height: 56px;
    display: flex;
    border-radius: 8px 8px 0 0;
    background-color: colors.$GRAY2;

    .tabItem {
      width: 50%;
      height: 100%;
      padding: 17px 0;
      color: colors.$GRAY4;
      @include fontSize.spoqaR14;
    }

    .focused {
      @include fontSize.spoqaB14;
      background-color: colors.$WHITE;
      color: colors.$GRAY8;
    }

    .left {
      border-radius: 8px 0 0 0;
    }

    .right {
      border-radius: 0 8px 0 0;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 40px;
  }
}

.bannerImg {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.btnBox {
  @include flexbox.flexbox(end);
}

.titleBtnStyle {
  text-decoration: underline;
  color: colors.$GRAY5;
}
