@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(center, center);
  width: 100%;
  height: 100%;
  position: relative;

  &::before {
    content: "";
    background: {
      position: center center;
      size: cover;
      image: url(../../../assets/images/pages/login/background-renewal.png);
    }
    opacity: 0.5;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  > * {
    width: 1268px;
    height: 738px;
    border-radius: 37px;
  }
}

.container {
  background-color: colors.$WHITE;
  @include flexbox.flexbox(center, center);
  position: absolute;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
}
