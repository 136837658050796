/* stylelint-disable scss/at-extend-no-missing-placeholder */
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/visual";

.targetDataWrapper {
  display: flex;
  padding: 40px;
  margin-bottom: 24px;
  background-color: colors.$WHITE;
  border-radius: 8px;
}

.targetData {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
}

.budgetWrapper {
  @extend .targetDataWrapper;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 8px;
}

.contentWrapper {
  @extend .budgetWrapper;
  gap: 24px;
}
