@use "style/config/mixins/visual";

.wrapper {
  width: 100%;
  height: calc(100% - 84px);
  overflow: auto;

  @include visual.noBackgroundScrollBar;

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 25%);

    &:hover {
      box-shadow: inset 6px 6px 0 rgba(34, 34, 34, 40%);
    }
  }
}
