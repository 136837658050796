@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .contentBox {
    @include flexbox.flexbox(_, center);
    @include fontSize.spoqaR16;
    gap: 13px;
  }

  label {
    @include fontSize.spoqaB18;
  }
}
