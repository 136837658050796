@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.inputBox {
  @include fontSize.spoqaR16;
  @include flexbox.flexbox(_, center);
  gap: 16px;
  width: 400px;
  height: 52px;
  padding: 15px 16px;
  border-radius: 16px;
  border: 1px solid colors.$GRAY5;

  input {
    width: 100%;
    height: 100%;

    &::placeholder {
      color: colors.$GRAY4;
    }

    // remove autofill bgc
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      box-shadow: 0 0 0 1000px white inset;
    }
  }

  &:focus-within {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 20%);
  }
}
