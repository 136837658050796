$WHITE: #ffffff;
$BLACK: #000000;

$GRAY1: #fafbfe;
$GRAY2: #eceef1;
$GRAY3: #d5d7dc;
$GRAY4: #c0c1c5;
$GRAY5: #959699;
$GRAY6: #747579;
$GRAY7: #595a5e;
$GRAY8: #222222;

$BLUE1: #eef7ff;
$BLUE2: #abd7ff;
$BLUE3: #57aeff;
$BLUE4: #1890ff;
$BLUE5: #1966db;
$BLUE6: #1850a4;

$RED1: #f5222d;
$RED2: #ffa39e;
$RED3: #fff1f0;

$ORANGE1: #fa541c;
$ORANGE2: #ffbb96;
$ORANGE3: #fff2e8;

$YELLOW1: #fa8c16;
$YELLOW2: #ffd591;
$YELLOW3: #fff7e6;

$GREEN1: #52c41a;
$GREEN2: #9ed683;
$GREEN3: #f6ffed;

$MINT1: #13c2c2;
$MINT2: #87e8de;
$MINT3: #e6fffb;

$PINK1: #eb2f96;
$PINK2: #ffadd2;
$PINK3: #fff0f6;

$PURPLE1: #a42feb;
$PURPLE2: #cd8af6;
$PURPLE3: #f5e6ff;

// $GRAY0: #ffffff;
// $GRAY1: #fafafa;
// $GRAY2: #f0f0f0;
// $GRAY3: #d9d9d9;
// $GRAY4: #bfbfbf;
// $GRAY5: #8c8c8c;
// $GRAY6: #595959;
// $GRAY7: #222222;

// $BLUE0: #e6f7ff;
// $BLUE1: #1890ff;
// $BLUE2: #096dd9;
// $BLUE3: #0051a3;

// $RED0: #f5222d;
// $RED1: #fff1f0;
// $RED2: #ffa39e;
// $RED3: #fc596c;

// $YELLOW1: #fff7e6;
// $YELLOW2: #ffd591;
// $YELLOW3: #fa8c16;

// $ORANGE1: #fff2e8;
// $ORANGE2: #ffbb96;
// $ORANGE3: #fa541c;

// $MINT1: #e6fffb;
// $MINT2: #87e8de;
// $MINT3: #13c2c2;

// $PINK1: #fff0f6;
// $PINK2: #ffadd2;
// $PINK3: #eb2f96;
