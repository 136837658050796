@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.uploadBox {
  @include flexbox.flexbox(_, center);

  label {
    @include flexbox.flexbox(center, center);
    @include fontSize.spoqaR12;
    width: 120px;
    height: 34px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    cursor: pointer;
  }

  .nullStyle {
    color: colors.$RED1;
    border: 1px solid colors.$RED1;
    background-color: colors.$RED3;
  }

  input[type="file"] {
    display: none;
  }

  .imgBox {
    @include flexbox.flexbox(center, center);
    height: 34px;
    gap: 10px;
    margin-left: 16px;

    a {
      color: colors.$GRAY6;
    }
  }

  .noneBox {
    display: none;
  }

  .cancelBtn {
    color: colors.$GRAY4;
    position: relative;
    font-size: 20px;
  }
}
