@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include fontSize.spoqaB18;
  color: colors.$GRAY8;

  span {
    color: colors.$RED1;
  }
}
