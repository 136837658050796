.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px 10px;

  .cnt {
    font-size: 16px;
    font-weight: 800;
    color: #1890ff;
  }

  .search {
    display: flex;
    gap: 20px;
  }

  label,
  .label {
    display: flex;
    gap: 10px;
    align-items: center;

    .txt {
      font-size: 16px;
      color: #656565;
    }

    input {
      padding: 5px;
      font-size: 14px;
      border: 1px solid #d5d7dc;
      border-radius: 8px;
    }

    input[name="username"] {
      width: 130px;
    }

    input[name="ip"] {
      width: 180px;
    }
  }

  button[name="search"] {
    width: 80px;
    height: 35px;
    font-size: 16px;
    font-weight: 800;
    color: #ffffff;
    background: #1890ff;
    border-radius: 8px;
  }

  button[name="reset"] {
    position: absolute;
    right: 26px;
    bottom: -15px;
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 14px;
    color: #c0c1c5;
  }
}
