@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  width: 100%;
  height: 150px;

  .chartWrapper {
    display: flex;

    .labelContainer {
      width: 60px;
      color: colors.$GRAY6;
      @include fontSize.spoqaR14;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .barChartContainer {
      width: 100%;
      height: 100%;
    }
  }

  .labelWrapper {
    display: flex;
    gap: 24px;
    color: colors.$GRAY6;
    @include fontSize.spoqaR14;

    div {
      display: flex;
      align-items: center;

      .boxStyle {
        width: 10px;
        height: 10px;
        background-color: var(--primary-color);
        margin-right: 6px;
        border-radius: 2px;
      }

      .color {
        background-color: colors.$BLUE3;
      }

      span {
        color: #262626;
        @include fontSize.spoqaB14;
        margin-left: 3px;
      }
    }
  }
}
