@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/pages/market/marketMixins" as market;

.wrapper {
  @include flexbox.flexbox(start, center);
  font-size: 14px;

  > span {
    margin: 0 10px;
  }
}

.buttonWrapper {
  margin-right: 10px;
}

.inputWrapper {
  @include flexbox.flexbox;
  gap: 9px;
}
