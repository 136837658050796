@use "style/pages/market/marketMixins" as market;

.subTitle {
  @include market.subTitle;
  margin-bottom: 8px;
}

.dataWrapper {
  @include market.dataWrapper;
  max-width: 1000px;
}
