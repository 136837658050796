// Bold
@mixin spoqaB12 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

@mixin spoqaB14 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

@mixin spoqaB16 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

@mixin spoqaB18 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

@mixin spoqaB20 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

@mixin spoqaB24 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

@mixin spoqaB28 {
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
}

@mixin spoqaB32 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

// ---------------------
// Regular
@mixin spoqaR10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

@mixin spoqaR12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@mixin spoqaR14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

@mixin spoqaR16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

// ---------------------
// Medium
@mixin spoqaM12 {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

@mixin spoqaM14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

@mixin spoqaM16 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
