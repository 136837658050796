/* stylelint-disable selector-class-pattern */
.dot-list-style {
  height: 300px;
  list-style: circle;
  list-style-type: ". ";
}

.dash-list-style {
  list-style-type: "- ";
}

.wrap {
  width: 100%;
  padding: 0 16px;

  p {
    margin: 17.6px 0;
  }

  ul {
    padding: 0;
    margin: 0;
    font-size: 1.1rem;
    list-style-type: none;
  }

  li {
    line-height: 1.2 !important;
  }

  ul ul {
    padding-left: 32px;
    margin-bottom: 1rem;
  }

  ul ul ul {
    padding: 10px 0 0 22px;
  }

  ul p {
    margin-top: 1rem;
    font-weight: bold;
    line-height: 1.5;
  }
}

.title {
  margin: 32px 0 0;
  font-size: 1.5rem;
  line-height: 35px;
}

.header {
  margin: 32px 0 26px;
  font-size: 1.5rem;
  line-height: 35px;
}

.header-desc {
  margin-bottom: 30px;
  font-size: 1rem;
}
