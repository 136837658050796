@use "style/config/constants/colors";

.input {
  width: 100%;
  max-width: 815px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 6px 16px;
  color: #595959;

  &:focus {
    border-color: colors.$BLUE4;
  }

  &::placeholder {
    color: #bfbfbf;
  }
}
