/* react-datepicker css custom */

.dateRange_customHeader__QU3Vd {
  padding-top: 2px !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #f0f0f0;
}

.react-datepicker__header {
  padding-bottom: 0;
  background-color: rgb(255, 255, 255);
  border-bottom: none;
}

.react-datepicker__day-names {
  width: 100%;
  padding: 0 6px;
}

.react-datepicker__day-name {
  color: rgba(0, 0, 0, 65%);
}

.react-datepicker__day--outside-month {
  /* visibility: hidden; */
  color: rgba(0, 0, 0, 25%) !important;
}

.react-datepicker__day--today {
  font-weight: normal;
  background-color: none !important;
}

.react-datepicker__day {
  width: 14.28%;
  padding: 1px;
  margin: 1px 0;
  font-family: "Spoqa Han Sans Neo", sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 85%);
  border-radius: 0 !important;
}

.react-datepicker__day:hover {
  color: rgb(255, 255, 255);
  background-color: #1890ff !important;
}

.react-datepicker__day--in-range {
  background-color: #e6f7ff;
}

.react-datepicker__day--in-selecting-range {
  width: 14.28%;
  padding: auto;
  margin-right: 0;
  margin-left: 0;
  background-color: #e6f7ff !important;
  border-radius: 0;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selected {
  color: rgb(255, 255, 255);
  background-color: #1890ff !important;
  border-top-left-radius: 10% !important;
  border-bottom-left-radius: 10% !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--selected {
  color: rgb(255, 255, 255);
  background-color: #1890ff !important;
  border-top-right-radius: 10% !important;
  border-bottom-right-radius: 10% !important;
}

.react-datepicker__day--selecting-range-start {
  color: rgb(255, 255, 255);
  background-color: #1890ff !important;
  border-top-left-radius: 10% !important;
  border-bottom-left-radius: 10% !important;
}

.react-datepicker__day--selecting-range-end {
  color: rgb(255, 255, 255);
  background-color: #1890ff !important;
  border-top-right-radius: 10% !important;
  border-bottom-right-radius: 10% !important;
}

.react-datepicker {
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 15%));
  border: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  border-bottom: 1px solid #f0f0f0;
}

.react-datepicker__day--disabled {
  color: rgba(0, 0, 0, 25%) !important;
}
