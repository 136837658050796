@use "style/config/constants/colors";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/flexbox";

.wrapper {
  width: 100%;

  a {
    @include fontSize.spoqaR14;
    @include flexbox.flexbox(start);
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    margin-bottom: 24px;
    line-height: 40px;
    border-radius: 8px;

    svg {
      margin-right: 16px;
    }
  }
}

.default {
  color: colors.$GRAY5;

  svg,
  svg > path {
    fill: colors.$GRAY5;
  }
}

.active {
  color: colors.$WHITE;
  background-color: var(--primary-color);

  svg,
  svg > path {
    fill: #fff;
  }
}
