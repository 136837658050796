@use "style/config/mixins/fontSize";
@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";

.wrapper {
  @include flexbox.flexbox(center);

  a {
    @include flexbox.flexbox(center, center);
    @include fontSize.spoqaR14;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border: 1px solid colors.$GRAY4;
    border-radius: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  .next,
  .prev {
    color: colors.$GRAY6;
  }

  .disabled {
    pointer-events: none;
    color: colors.$GRAY4;
  }

  .active {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}
