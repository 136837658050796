@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.top {
  @include flexbox.flexbox(end);
  width: calc(100% - 240px);
  background-color: colors.$WHITE;

  a,
  button {
    @include fontSize.spoqaR12;
    @include flexbox.flexbox(start);
    color: colors.$GRAY8;
    margin-left: 8px;

    svg {
      margin-left: 4px;
    }
  }
}
