@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #262626, $alpha: 50%);
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: colors.$WHITE;
  width: 400px;
  height: 410px;

  .header {
    @include flexbox.flexbox(between, center);
    height: 44px;
    padding: 12px 24px;
    background-color: #096dd9;

    h1 {
      color: colors.$WHITE;
      @include fontSize.spoqaB16;
    }

    button {
      font-size: 20px;
      color: colors.$WHITE;
    }
  }

  .contentContainer {
    padding: 24px 33px 26px 24px;

    .box {
      .title {
        @include fontSize.spoqaM14;
        margin-bottom: 8px;
      }

      ul {
        padding-left: 24px;

        li {
          list-style-type: disc;
          font-size: 12px;
          line-height: 20px;
          color: #595959;

          &:last-child {
            margin-bottom: 16px;
          }

          .underLine {
            color: #595959;
            border-bottom: 1px solid #595959;
          }
        }
      }
    }
  }
}
