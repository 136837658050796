@use "style/config/constants/colors";

.label {
  display: flex;
  align-items: center;
  min-width: 100px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: colors.$GRAY6;

  span {
    visibility: hidden;
    color: #ff0000;
  }

  .required {
    visibility: visible;
  }
}
