@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

@mixin subTitle {
  @include fontSize.spoqaB18;
}

@mixin dataWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 16px;
}
