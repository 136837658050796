@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";
@use "style/config/mixins/position";
@use "style/config/mixins/visual";

.wrapper {
  position: relative;
  width: 600px;
  height: 34px;
  border: 1px solid colors.$GRAY3;
  border-radius: 8px;
  padding: 7px 16px;

  .buttonStyle {
    @include flexbox.flexbox(_, center);
    width: 100%;
    height: 100%;
    color: colors.$GRAY4;
    @include fontSize.spoqaR14;

    &::before {
      @include position.posCenterY;
      height: 50%;
      right: 16px;
      color: colors.$GRAY6;
      content: url(../../../../assets/svgs/icon/down-triangle.svg);
      transition: 0.5s;
    }
  }

  ul.option {
    @include visual.noBackgroundScrollBar;
    position: absolute;
    top: 110%;
    left: 0%;
    z-index: 1;
    width: 100%;
    max-height: 290px;
    overflow: auto;
    border-radius: 8px;
    background-color: colors.$WHITE;
    border: 1px solid colors.$GRAY3;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);

    li > button {
      width: 100%;
      word-break: keep-all;
      color: colors.$GRAY8;
      padding: 12px 16px;
      @include fontSize.spoqaR14;

      &:hover {
        background: var(--primary-color);

        div {
          color: colors.$WHITE;

          span {
            color: colors.$WHITE;
          }
        }
      }
    }
  }
}
