@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  display: flex;

  .statusFilterBox {
    @include flexbox.flexbox(_, center);

    & > button {
      @include fontSize.spoqaR14;
      margin-right: 8px;
      color: colors.$GRAY4;

      & > span {
        font-weight: 700;
        color: colors.$GRAY6;
        margin-left: 4px;
      }
    }

    .active {
      color: var(--primary-color);
    }
  }
}
