@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.container {
  overflow: auto;
  flex-direction: column;
  background-color: colors.$WHITE;
  border-radius: 8px;
  margin-bottom: 24px;

  .tabContainer {
    width: 100%;
    height: 60px;
    display: flex;
    border-radius: 8px 8px 0 0;
    background-color: colors.$GRAY2;

    .tabItem {
      width: 50%;
      height: 100%;
      padding: 17px 0;
      border-radius: 8px 8px 0 0;
      color: colors.$GRAY4;
      @include fontSize.spoqaR14;
    }

    .focused {
      background-color: colors.$WHITE;
      color: colors.$GRAY8;
      font-weight: 700;
    }

    .left {
      border-radius: 8px 0 0 0;
    }

    .right {
      border-radius: 0 8px 0 0;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    height: 366px;

    .detailInfoContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .labelContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .dateContainer {
          display: flex;
          gap: 8px;

          div {
            color: colors.$GRAY5;
            @include fontSize.spoqaR16;
          }
        }
      }
    }

    .chartBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
  }

  .infoContainer {
    height: 366px;
  }
}
