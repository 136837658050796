@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  @include flexbox.flexbox(start, start);
  white-space: nowrap;
}

.areaWrapper {
  @include flexbox.flexbox(start, center);
  flex-wrap: wrap;
  gap: 10px;
  max-width: 500px;

  > button {
    margin: 0;
  }
}
