@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.customInput,
.rangeInput {
  @include flexbox.flexbox(between, center);
  @include fontSize.spoqaR14;
  width: 220px;
  height: 34px;
  padding: 9px 16px;
  border: 1px solid colors.$GRAY3;
  border-radius: 8px;

  &Placeholder {
    color: colors.$GRAY4;
  }
}

.customHeader {
  display: flex;
  justify-content: space-between;
  padding: 6px 15px 10px 15px;
  border-bottom: 1px solid colors.$GRAY2;

  svg {
    width: 6px;
    height: 10px;
  }

  .decrease {
    @include flexbox.flexbox(_, center);
    gap: 10px;

    &Year {
      display: flex;
    }
  }

  .increase {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .decrease;
    rotate: 180deg;

    &Year {
      display: flex;
    }
  }
}

.btnContainer {
  button {
    width: 50px;
    height: 24px;
    background-color: var(--primary-color);
    color: colors.$WHITE;
    border-radius: 4px;
    @include fontSize.spoqaR12;
    margin: 10px 0;
  }

  .deleteBtn {
    float: left;
  }

  .okBtn {
    float: right;
  }
}
