@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.backButton {
  display: block;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  color: colors.$BLACK;
  text-decoration: underline;
}

.wrapper {
  @include flexbox.flexbox("center");
  flex-direction: column;
  width: 1045px;
  height: 100vh;
  margin: 0 auto;
}

.content {
  @include flexbox.flexbox("between");
  width: 1045px;
  height: fit-content;
  margin: 0 auto 240px;

  img {
    width: 490px;
  }

  p {
    color: colors.$BLACK;

    &:first-of-type {
      font: {
        weight: 500;
        size: 96px;
        height: 120px;
      }
      margin-bottom: 18px;
    }

    &:last-of-type {
      font-size: 18px;
      font-weight: 400;
      line-height: 34px;
      white-space: pre-wrap;
    }
  }
}
