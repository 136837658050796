@use "style/config/constants/colors";
@use "style/config/mixins/flexbox";
@use "style/config/mixins/fontSize";

.wrapper {
  position: relative;
  @include flexbox.flexbox(_, start);

  .dateBox {
    @include flexbox.flexbox(_, center);
    gap: 8px;

    span {
      @include fontSize.spoqaM14;
      color: colors.$GRAY5;
    }

    .searchKeyword {
      @include fontSize.spoqaR14;
      width: 220px;
      height: 34px;
      padding: 9px 16px;
      border: 1px solid colors.$GRAY3;
      border-radius: 8px;

      &::placeholder {
        color: colors.$GRAY4;
      }
    }
  }

  .searchClearBtn {
    position: absolute;
    @include flexbox.flexbox(center, center);
    @include fontSize.spoqaR14;
    top: calc(100% + 8px);
    right: 0px;
    gap: 4px;
    color: colors.$GRAY4;
  }
}
